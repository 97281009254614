<template>
  <v-app>
    <v-app-bar app style="height: 90px;" class="d-flex align-center">
      <v-container align="center" class="d-flex align-center no-gutters">
        <v-row class="d-flex align-center">
          <v-col cols="6" class="mt-6 d-flex align-center">
            <v-row align="center" class="d-flex align-center">
              <v-col cols="2">
                <v-img src="./assets/logo.png" height="70" />  
              </v-col>
              <v-col cols="2">
                <span style="font-weight: bold; font-size: 20px;">Psy-Box</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="mt-6">
            <v-row align="center" class="d-flex align-center no-gutters justify-end" style="justfiy-content: flex-end">
              <v-col cols="3">
                <v-btn color="black">Login</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="white" style="background-color: #5F60AF" outlined>Register</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="text-center mt-20">
            <h3 class="font-weight-bold mt-50" style="font-size: 60px;">SPECIALIST'S CHEMISTRY, CLIENT'S ANTIDEPRACE</h3>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <br>
            <br>
            <br>
            <br>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h3 font-weight-bold mb-3">How to use Psy-Box?</h2>
            <br>
            <br>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-card class="pa-6">
              <v-icon size="48" color="#5F60AF">mdi-account-circle</v-icon>
              <h3 class="text-h5 font-weight-bold mt-4 mb-2">Create an account</h3>
              <p class="text-h6 font-weight-medium grey--text">
                Sign up for free to get access to all our features
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-6">
              <v-icon size="48" color="#5F60AF">mdi-calendar-clock</v-icon>
              <h3 class="text-h5 font-weight-bold mt-4 mb-2">Book a session</h3>
              <p class="text-h6 font-weight-medium grey--text">
                Schedule an appointment with one of our expert psychologists
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-6">
              <v-icon size="48" color="#5F60AF">mdi-chat-outline</v-icon>
              <h3 class="text-h5 font-weight-bold mt-4 mb-2">Join a session</h3>
              <p class="text-h6 font-weight-medium grey--text">
                Connect with your psychologist through our secure chat system
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
          <br>
          <br>
          <br>
          <br>
        <v-row class="d-flex no-gutter align-center">
          <v-col cols="6">
            <h3 class="text-h3 font-weight-bold">Why Psy-Box ?</h3>
            <p class="text-h6 font-weight-medium grey--text">
                Schedule an appointment with one of our expert psychologists
            </p>
          </v-col>
          <v-col cols="6">
            <v-img src="./assets/soru.svg" height="600"></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex no-gutter align-center">
          <v-col cols="6">
            <v-img src="./assets/appv1.svg" height="600"></v-img>
          </v-col>
          <v-col cols="6">
            <h3 class="text-h3 font-weight-bold" style="color:#5F60AF">Download</h3>
            <h3 class="text-h3 font-weight-bold">Psy-Box Right Now</h3>
            <p class="text-h6 font-weight-medium grey--text">
                Discover the advantages only for you
            </p>
            <v-img src="./assets/appstorev1.svg" height="100" style="margin-top: 20px"></v-img>
          </v-col>
        </v-row>
        <h3 class="text-h3 font-weight-bold">Create Mentor of <span class="text-h3 font-weight-bold" style="color: #5F60AF">Psy-Box</span></h3>
        <h3 class="text-h3 font-weight-bold">In 2 Steps</h3>
        <v-row class="d-flex no-gutter align-center">
          <v-col cols="6">
            <v-img src="./assets/kayıtai.svg" height="350"></v-img>
            <h2 style="color: #5F60AF">Create Profile</h2>
            <p>Psy-Box is working with registration flow</p>
          </v-col>
          <v-col cols="6">
            <v-img src="./assets/onayai.svg" height="350"></v-img>
            <h2 style="color: #5F60AF">Instant Approve</h2>
            <p>All the consultants with licenses will be approved</p>
          </v-col>
        </v-row> 
      </v-container>
      <v-footer color="#10102B" app height="120">
      <v-container align="center" class="d-flex align-center no-gutters">
        <v-row class="d-flex align-center">
          <v-col cols="6" class="mt-6 d-flex align-center">
            <v-row align="center" class="d-flex align-center">
              <v-col cols="2">
                <v-img src="./assets/logo.png" height="70" />  
              </v-col>
              <v-col cols="2">
                <span style="font-weight: bold; font-size: 20px; color:white">Psy-Box</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="mt-6">
            <v-row align="center" class="d-flex align-center no-gutters justify-end" style="justfiy-content: flex-end">
              <v-col cols="4">
                <v-btn icon href="https://www.instagram.com/" class="mr-2">
                  <v-icon size="24">mdi-instagram</v-icon>
                </v-btn>
                <v-btn icon href="https://twitter.com/" class="mr-2">
                  <v-icon size="24">mdi-twitter</v-icon>
                </v-btn>
                <v-btn icon href="https://www.linkedin.com/">
                  <v-icon size="24">mdi-linkedin</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
  </v-footer>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
